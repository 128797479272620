import { render, staticRenderFns } from "./IndexDealsToRecall.vue?vue&type=template&id=543a8db6&scoped=true&"
import script from "./IndexDealsToRecall.vue?vue&type=script&lang=js&"
export * from "./IndexDealsToRecall.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "543a8db6",
  null
  
)

export default component.exports