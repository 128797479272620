<template>
  <div class="row">
    <div class="col-12">
      <deals-to-recall-datatable/>
    </div>
  </div>
</template>

<script>
import DealsToRecallDatatable from "@/pages/Deals/datatables/DealsToRecallDatatable";
export default {
  name: "IndexDealsToRecall.vue",
  components: {DealsToRecallDatatable}
}
</script>

<style scoped>

</style>
